/* default css */
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/********************************************** helper css **********************************************/

/*** TYPOGRAPHY  ***/
#root, .ui.header, .ui.menu, .ui.button, .ui.modal{
    font-family: 'Noto Sans CJK TC', Noto, Helvetica, Arial, 'Microsoft JHengHei', sans-serif !important;
}

#root {
    background-color: #fff;
    position: fixed;
    top: 0; bottom: 0; left: 0; right: 0;
    overflow-y: scroll;
    display: flex;
    overflow: hidden;
    flex-direction: column;
}

.full-height {
    min-height: 100vh;
}

/* main content container */
#main {
    display: block;
    flex: 1 1;
    margin: 0;
    padding: 0;
    padding-bottom: 2rem;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    z-index: 0;
}

/* nav menu font bold */
.ui.menu .item {
    font-weight: bolder !important;
}

#root .ui.menu{
    margin-top: 0;
    margin-bottom: 0;
}

/* cursor */
.cursor {
    cursor: pointer;
}

.cursor-default{
    cursor: auto !important;
    cursor: initial !important;
}

/* error message */
.errorMsg {
    margin-top: 8px;
    color: red;
}

/* text align center */
.text-center {
    text-align: center;
}

/* from group margin */
.form-group-margin {
    margin-bottom: 2em !important;
}

/* margin for fields in form group */
.fields.grouped>.field {
    margin-bottom: 1em !important;
}

.fullwidth{
    width: 100%;
}

.main.ui.grid>div{
    padding-left: 0;
    padding-right: 0;
}

.ui.basic.segment{
    padding: 0;
}

h1.ui.header{
    margin-bottom: 0.5rem !important;
}

#countdown{
    font-size: 4rem;
    padding: 1rem 0;
    border: 0.25rem #f2711c solid;
}

.ui.form .field>label{
    text-align: left;
}

.ui.header:first-child {
    margin-top: 0 !important;
}

#main>.ui.table{
    margin: 0 2.5%;
    padding: 0;
}

/****** INCREMENT COMPONENT ******/

.increment{
    margin: 1rem 0;
}

.increment .ui.button{
    margin: 0;
}

.increment.ui.disabled.input{
    opacity: 0.8;
    text-align: center;
}


.increment.ui.disabled>input{
    padding: 0 0.5rem;
    text-align: center;
}

.increment>.ui.compact.button{
    padding: .58928571em 0;
    width: 2.16em;
}

.disabled .increment.ui>.button{
    opacity: 0.6;
}

/* DQ */
tr.disabled>td>.button{
    opacity: 0.6;
}

/****** RESPONSIVE ******/

@media only screen and (max-width: 1000px){
    .seven-rem{
        width: auto !important;
        width: initial !important;
    }
    .eight-rem{
        width: auto !important;
        width: initial !important;
    }
}
@media only screen and (max-width: 991px) and (min-width: 768px){
    #main .ui.container {
        width: 100%;
    }
}
@media only screen and (max-width: 640px){
    .ui.table.fixed.responsive td{
        overflow: visible;
        text-overflow: unset;
        padding: 0.5rem;
    }
}
